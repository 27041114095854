import React from "react";
import { observer } from "mobx-react-lite";
import { Button, InputGroup, Switch } from "@blueprintjs/core";

import { ImagesGrid } from "polotno/side-panel/images-grid";

import { SectionTab } from "polotno/side-panel";
import * as api from "../api";
import { Templates } from "../components/icons/templates";

export const MyTemplatesPanel = observer(({ store }) => {
  const [templatesLoadings, setTemplatesLoading] = React.useState(false);
  const [templates, setTemplates] = React.useState({});
  const [sameSize, setSameSize] = React.useState(true);
  const [query, setQuery] = React.useState("");
  const [page, setPage] = React.useState(1);

  const loadTemplates = async (newRequest = false) => {
    const PER_PAGE = 15;
    setTemplatesLoading(true);
    if (newRequest) {
      setPage(1);
      setTemplates({});
    }

    const list = await api.listTemplates({
      query,
      perPage: PER_PAGE,
      page: newRequest ? 1 : page,
      sameSize,
      width: store.width,
      height: store.height,
    });

    const newList = {
      hits: list.hits,
      items:
        templates?.items?.length > 0 && !newRequest
          ? [...templates.items, ...list.items]
          : [...list.items],
      totalPages: list.totalPages,
    };

    setTemplates(newList);
    setPage((prev) => prev + 1);

    setTemplatesLoading(false);
  };

  const handleSelect = async (template) => {
    const designId = await localStorage.getItem(
      "braip-creative-last-design-id"
    );
    window.project.loadByTemplateId({ template_id: template.id, designId });
  };
  React.useEffect(() => {
    loadTemplates(true);
  }, []);

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <InputGroup
        leftIcon="search"
        placeholder={"Pesquisar modelos..."}
        onChange={(e) => setQuery(e.target.value)}
        style={{
          marginBottom: "16px",
        }}
      />
      <Switch
        checked={sameSize}
        onChange={(e) => setSameSize(e.target.checked)}
        alignIndicator="right"
        style={{
          marginBottom: "16px",
        }}
      >
        Mostrar modelos do mesmo tamanho
      </Switch>
      <Button
        fill
        intent="primary"
        onClick={() => {
          loadTemplates(true);
        }}
        style={{
          marginBottom: "16px",
        }}
      >
        Buscar modelos
      </Button>
      {!templatesLoadings && !templates?.items?.length && (
        <div style={{ paddingTop: "20px", textAlign: "center", opacity: 0.6 }}>
          Nenhum modelo encontrado...
        </div>
      )}
      <ImagesGrid
        shadowEnabled={false}
        rowsNumber={2}
        images={templates?.items}
        getPreview={(image) => image.thumbnail}
        isLoading={templatesLoadings}
        loadMore={templates?.items?.length < templates.hits && loadTemplates}
        onSelect={(template) => handleSelect(template)}
        crossOrigin={false}
      />
    </div>
  );
});

// define the new custom section
export const MyTemplatesSection = {
  name: "templates",
  Tab: (props) => (
    <SectionTab name="Modelos" {...props}>
      <Templates />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: MyTemplatesPanel,
};
